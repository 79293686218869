var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _vm.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _vm._e(),
      _c("ASuccessFour", { attrs: { api: _vm.api } }),
      !_vm.api.isLoading && _vm.data != null
        ? _c(
            "v-row",
            { staticClass: "mt-3" },
            [
              _c(
                "v-col",
                {
                  staticClass: "mx-auto",
                  attrs: {
                    align: "center",
                    justify: "center",
                    cols: "12",
                    sm: "8",
                  },
                },
                [
                  _c(
                    "v-col",
                    { attrs: { align: "start", justify: "start" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mb-3",
                          attrs: { color: "primary" },
                          on: { click: _vm.redirectBack },
                        },
                        [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    { staticClass: "pa-3", attrs: { "elevation-1": "" } },
                    [
                      _c(
                        "v-toolbar",
                        { staticClass: "secondary white--text pa-1" },
                        [
                          _c("v-toolbar-title", { staticClass: "text-h5" }, [
                            _vm._v(" Special Remark "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mt-5" },
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-start px-3 mt-3",
                                  },
                                  [
                                    _c("v-textarea", {
                                      attrs: {
                                        outlined: "",
                                        "auto-grow": "",
                                        label: "Please write your remark...",
                                        width: "auto",
                                        height: "auto",
                                      },
                                      model: {
                                        value: _vm.data.remark,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.data, "remark", $$v)
                                        },
                                        expression: "data.remark",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-end" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { color: "primary", plain: "" },
                                        on: {
                                          click: function () {
                                            _vm.$router.go(-1)
                                          },
                                        },
                                      },
                                      [_vm._v(" Cancel ")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { color: "primary" },
                                        on: { click: _vm.validateInput },
                                      },
                                      [_vm._v(" Submit ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }